function travelling(){
    var url=new Array();

    url[0]="https://github.com/volfclub/travelling";
    url[1]="https://www.volf.club";
    url[2]="https://sonic.volf.club";
    url[3]="https://yltang.cn";
    url[4]="https://guguga.cn";
    url[5]="https://mQAQm.com";
    url[6]="https://www.yamdr.cn";
    url[7]="https://www.inspire2030.cn";
    url[8]="https://ryanc.cc";
    url[9]="https://seija.me";
    url[10]="https://blog.uniartisan.com";
    url[11]="https://jinqimu.xyz";
    url[12]="https://crowndaisy.com";
    url[13]="https://heycmm.cn";
    url[14]="https://travellings.now.sh/assets/rip.html";
    url[15]="https://jinjis.cn";
    url[16]="https://blog.tigerxly.com";
    url[17]="http://blog.diego.plus:5800 ";
    url[18]="https://sublimerui.top";
    url[19]="https://cwxyr.me";
    url[20]="https://mmyyll.ml";
    url[21]="https://travellings.now.sh/assets/rip.html";
    url[22]="https://449293786.site";
    url[23]="https://www.naraku.cn";
    url[24]="https://www.xiyo8.cn";
    url[25]="https://www.wangkai88.com";
    url[26]="https://blog.zeruns.tech";
    url[27]="https://guqing.xyz";
    url[28]="https://lxxs.xyz";
    url[29]="https://feiliwuyan.com";
    url[30]="https://www.m78.co";
    url[31]="https://icheung.net";
    url[32]="https://www.sitstars.com";
    url[33]="https://ionssource.cn";
    url[34]="https://travellings.now.sh/assets/rip.html";
    url[35]="https://blog.kaygb.com";
    url[36]="https://www.yunyoujun.cn";
    url[37]="https://nasity.cn";
    url[38]="https://guhub.cn";
    url[39]="https://yuaneuro.cn";
    url[40]="https://travellings.now.sh/assets/rip.html";
    url[41]="https://krau.top";
    url[42]="https://wfblog.net";
    url[43]="https://www.nymane.xyz";
    url[44]="https://blog.yilon.top";
    url[45]="https://blog.ajian.online";
    url[46]="https://www.freejishu.com";
    url[47]="https://713.moe";
    url[48]="https://zhoublog.xyz";
    url[49]="https://dpii.club";
    url[50]="https://core.moe";
    url[51]="https://www.solarme.vip";
    url[52]="https://spblog.tk";
    url[53]="https://www.lin03.cn";
    url[54]="https://sqdxwz.top";
    url[55]="https://travellings.now.sh/assets/rip.html";
    url[56]="https://blog.tigerroot.cn";
    url[57]="https://kylin.dev";
    url[58]="https://moefishtang.xyz";
    url[59]="https://www.pluvet.com";
    url[60]="https://travellings.now.sh/assets/rip.html";
    url[61]="https://www.6zgm.com";
    url[62]="https://blog.aenjoy.cn";
    url[63]="https://blog.lifeisgg.online";
    url[64]="https://blog.ojhdt.com";
    url[65]="https://moechu.cn";
    url[66]="https://www.zqcnc.cn";
    url[67]="https://blog.mboker.cn";
    url[68]="https://wznmickey.com";
    url[69]="https://blog.xiadengma.top";
    url[70]="https://www.jsxlo.com";
    url[71]="https://blog.iswl.vip";
    url[72]="https://paperbox.xyz";
    url[73]="https://www.isolitude.cn";
    url[74]="https://gupan.site";
    url[75]="https://www.itggg.cn";
    url[76]="https://moshanghua.net";
    url[77]="https://blog.endureblaze.cn";
    url[78]="https://blog.jiehua1995.xyz";
    url[79]="https://you.ci";
    url[80]="https://www.deepwave.top";
    url[81]="https://skywt.cn";
    url[82]="https://lipk.org";
    url[83]="https://www.zkl2333.com";
    url[84]="https://www.whoit.top";
    url[85]="https://takuron.top";
    url[86]="https://dlizi.com";
    url[87]="https://www.wasabi.fun";
    url[88]="https://yingserver.cn";
    url[89]="https://www.fzxx.xyz";
    url[90]="https://noheart.cn";
    url[91]="https://www.addesp.com";
    url[92]="https://blog.devyi.com";
    url[93]="https://blog.mhuig.top";
    url[94]="https://royce2003.top";
    url[95]="https://blog.6yfz.cn";
    url[96]="https://www.geekera.cn";
    url[97]="https://xiaowei.china97.cn";
    url[98]="https://kissbaofish.cn";
    url[99]="https://blog.fkun.tech";
    url[100]="https://seiryu.cn";
    url[101]="https://blog.chrxw.com";
    url[102]="https://www.luxinzhangyun.top";
    url[103]="https://www.tabirstrees.top";
    url[104]="https://www.googlessr.top";
    url[105]="https://myheod.cn";
    url[106]="https://www.ishells.cn";
    url[107]="https://www.wztlink1013.com";
    url[108]="https://zkpeace.com";
    url[109]="https://userunknown.gq";
    url[110]="http://ilovey.live";
    url[111]="https://code016.com";
    url[112]="https://jimmyqin.cn";
    url[113]="https://wangpl.top";
    url[114]="https://www.yansanlu.com";
    url[115]="https://www.fddmao.com";
    url[116]="https://www.y2jq.com";
    url[117]="https://www.chirmyram.top";
    url[118]="https://jiuxuxinchen.xyz";
    url[119]="https://blog.lu-sky.com";
    url[120]="https://mzrme.com";
    url[121]="https://www.tanknee.cn";
    url[122]="https://fx7.top";
    url[123]="https://blog.butanediol.me";
    url[124]="https://liuyifei.club";
    url[125]="https://www.ddg.ink";
    url[126]="https://blog.zggsong.cn";
    url[127]="https://bestzuo.cn";
    url[128]="https://handsonic.top";
    url[129]="https://travellings.now.sh/assets/rip.html";
    url[130]="https://sorryfu.top";
    url[131]="https://taog.top";
    url[132]="https://innei.ren";
    url[133]="https://dusays.com";
    url[134]="https://www.wangyusong.cn";
    url[135]="https://blog.zcmimi.top";
    url[136]="https://blog.tengfei.website";
    url[137]="https://www.zk1220.com";
    url[138]="https://jishujun.com";
    url[139]="https://colsrch.top";
    url[140]="https://azusemisa.top";
    url[141]="https://uts.ski";
    url[142]="https://www.yilantingfeng.site";
    url[143]="https://hasaik.com";
    url[144]="https://shoka.lostyu.me";
    url[145]="https://rain.moimo.me";


    if (document.referrer) {
      var origin = new URL(document.referrer).origin;
      url.splice(url.indexOf(origin), 1);
    }
    
    
    var ints=Math.floor(Math.random() * url.length);
    window.location=url[ints];
   }
